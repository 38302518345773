import React, { FC, useEffect, useRef } from 'react';

interface CKEditorComponentProps {
  value: any
}

const CKEditorComponent: FC<CKEditorComponentProps> = ({ value }) => {
  const mathContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_CHTML";
    script.async = true;
    script.onload = () => {
      window.MathJax.Hub.Config({
        extensions: ["mml2jax.js"], // Enable MathML support
        jax: ["input/MathML", "output/HTML-CSS"],
        "HTML-CSS": { availableFonts: ["TeX"] }
      });

      if (mathContainerRef.current) {
        mathContainerRef.current.innerHTML = value;
      }

      if (window.MathJax) {
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [value]);

  return (
    <div>
      <div ref={mathContainerRef}></div>
    </div>
  );
};

export default CKEditorComponent;
