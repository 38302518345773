import { Col, Progress, Row } from "antd";
import React, { FC, Fragment, useEffect, useState } from "react";
import PrespectiveStack from "../.../../../../assets/icon/prespectiveStack.svg";
import Waring from "../.../../../../assets/icon/warning.svg";
import CloseIcon from "../.../../../../assets/icon/SVG/closeWrongWhite.svg";
import Option from "./Option";
import "./examPannel.scss";
import ExamPannelController from "./ExamPannelController";
import QuestionsDrawer from "./QuestionsDrawer";
import QuestionContainer from "../../../store/container/QuestionContainer";
import { QuestionReducerProps } from "../../../store/reducers/questionReducer";
import { ExamService } from "../../../services/ExamService/exam.service";
import PopupModal from "../../components/PopupModal";
import {
  ExcAtmptQuestAttchmtModel,
  Question as QuestionModel,
} from "../../../models/Question/question.model";
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import UIButton from "../UIButton";
import Timer from "../../utils/Timer";
import {
  EXERCISE_EXAM,
  PAPER_EXAM,
  PAPER_EXAM_RESULTS,
  PRACTICE_CHAPTERS,
  PRACTICE_EXAMS,
  EXERCISE_EXAM_RESULTS,
  STUDY_MATERIAL,
  QUICK_QUIZ_RESULTS,
  QUICK_QUIZ,
} from "../../../routes/routeConstants/appRoutes";
import AuthContainer from "../../../store/container/AuthContainer";
import { ExerciseService } from "../../../services/ExerciseService/exercise.service";
import { Input } from "antd";
import {
  HourglassOutlined,
  CommentOutlined,
  SolutionOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  BookOutlined,
  EyeOutlined,
  EyeFilled,
} from "@ant-design/icons";
import { PreviousPaperService } from "../../../services/PreviousPaperService/previouspaper.service";
import InstructionPopup from "../../../views/Home/PracticeModule/PracticeChapter/Quizes/Instruction";
import { Attachment } from "../Attachment";
import { ContentService } from "../../../services/ContentService/content.service";
import { LocalStorage } from "../../utils/LocalStorageHelpers";
import { convertJSONToFormData } from "../../utils/dataFormatConverter";
import { serialize } from "serializr";
import TitleContainer from "../../../store/container/TitleContainer";
import CKEditorComponent from "../CKEditorComponent";
const { TextArea } = Input;

// const BASE_URL = process.env.REACT_APP_BASE_URL;

interface ExamPannelProps extends QuestionReducerProps {
  type: string;
  setTitle: Function;
}

const ExamPannel: FC<ExamPannelProps> = ({
  remaning,
  attempt,
  questions,
  currentQuestion,
  setCurrentQuestion,
  setVisitedQuestion,
  setMarkedQuestion,
  setExamAttempt,
  setQuestions,
  updateQuestion,
  setTitle,
  type,
}) => {
  const history = useHistory();
  const params: any = useParams();
  const location: any = useLocation();
  const { state }: { state: any } = useLocation();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [questionsDrawer, setQuestionDrawer] = useState(false);
  const [optionChanged, setOptionChanged] = useState(false);
  const [quitQuiz, setQuitQuiz] = useState(false);
  const [resultPage, setResultPage] = useState(false);
  const [finish, setFinish] = useState(false);
  const [currentTextAnswer, setCurrentTextAnswer] = useState<string>("");
  const [hideLastQuestion, setHideLastQuestion] = useState<boolean>(false);
  const [infoPopup, setInfoPopup] = useState(false);

  const getTimeType = (selected: string, time: number) => {
    switch (selected) {
      case "whole_quiz":
        return time * 60;
      case "per_question":
        return time;
      default:
        return null;
    }
  };

  useEffect(() => {
    setTitle({
      twoBags: false,
    });
    setSelectedOption(null);
    setOptionChanged(false);
    if (location?.pathname.includes("results")) {
      setResultPage(true);
    } else {
      if (location.pathname.includes("paper")) {
        PreviousPaperService.getPaperAttemptQuestion(
          {
            attemptId: attempt?.id,
            id: questions[currentQuestion]?.id,
          },
          (question) => {
            if (question?.studentAnswer) {
              Object.entries(
                questions[currentQuestion]?.options ?? {}
              )?.forEach(([key, value]) => {
                if (
                  key === question?.studentAnswer &&
                  location?.pathname?.split("/")[5] !== "results"
                ) {
                  setSelectedOption(key);
                }
              });
            }
          },
          () => {
            history.goBack();
          }
        );
      } else if (location.pathname.includes("quick_quiz")) {
        ContentService.getContentAttemptQuestion(
          {
            attemptId: attempt?.id,
            questionId: questions[currentQuestion]?.id,
            courseId: LocalStorage.getItem("COURSE_ID"),
          },
          (question) => {
            if (question?.studentAnswer) {
              Object.entries(
                questions[currentQuestion]?.options ?? {}
              )?.forEach(([key, value]) => {
                if (
                  key === question?.studentAnswer &&
                  location?.pathname?.split("/")[5] !== "results"
                ) {
                  setSelectedOption(key);
                }
              });
            }
          },
          () => {
            history.goBack();
          }
        );
      } else {
        type == "exam"
          ? ExerciseService.getQuestion(
              {
                attemptId: attempt?.id,
                id: questions[currentQuestion]?.id,
              },
              (question) => {
                if (question?.studentAnswer) {
                  Object.entries(
                    questions[currentQuestion]?.options ?? {}
                  )?.forEach(([key, value]) => {
                    if (
                      key === question?.studentAnswer &&
                      location?.pathname?.split("/")[5] !== "results"
                    ) {
                      setSelectedOption(key);
                    }
                  });
                }
              },
              () => {
                history.goBack();
              }
            )
          : ExamService.getQuestion(
              {
                attemptId: attempt?.id,
                id: questions[currentQuestion]?.id,
              },
              (question) => {
                if (question?.studentAnswer) {
                  Object.entries(
                    questions[currentQuestion]?.options ?? {}
                  )?.forEach(([key, value]) => {
                    if (
                      key === question?.studentAnswer &&
                      location?.pathname?.split("/")[5] !== "results"
                    ) {
                      setSelectedOption(key);
                    }
                  });
                }
              },
              () => {
                history.goBack();
              }
            );
      }
    }
  }, [currentQuestion]);

  const handleSelect = (selected: string, value: string) => {
    let updatedQuestion = questions[currentQuestion];
    updatedQuestion.studentAnswer = selected;
    updateQuestion(updatedQuestion);
    setOptionChanged(true);
    setSelectedOption((alreadySelected) => {
      if (alreadySelected === selected) {
        setOptionChanged(false);
        return null;
      }
      return selected;
    });
  };

  const handleNext = () => {
    if (currentQuestion === questions?.length - 1) return;
    setCurrentQuestion(currentQuestion + 1);
  };

  const handlePrev = () => {
    if (currentQuestion === 0) return;
    setCurrentQuestion(currentQuestion - 1);
  };

  // useEffect(() => {
  //   if (questions[currentQuestion].onlyFreeText) {
  //     setCurrentTextAnswer(questions[currentQuestion].studentAnswer ?? "");
  //   }
  // }, [currentQuestion]);

  const handleSubmit = () => {
    const params = {
      attemptId: attempt?.id,
      id: questions[currentQuestion]?.id,
    };
    const options: any = questions[currentQuestion]?.options;
    const excerciseQuestionAttacments: any =
      questions[currentQuestion].excAtmptQuestAttchmts ?? [];
    const answer = questions[currentQuestion]?.onlyFreeText
      ? {
          student_answer: questions[currentQuestion].studentAnswer,
          exc_atmpt_quest_attchmts: serialize(
            ExcAtmptQuestAttchmtModel,
            excerciseQuestionAttacments
          ),
        }
      : { student_answer: questions[currentQuestion].studentAnswer };
    try {
      if (location.pathname.includes("paper")) {
        PreviousPaperService.updatePaperAttemptAnswer(
          params,
          answer,
          () => {
            setHideLastQuestion(questions?.length === currentQuestion + 1);
            setCurrentTextAnswer("");
            setVisitedQuestion(currentQuestion);
            handleNext();
            setOptionChanged(false);
          },
          () => {}
        );
      } else if (location.pathname.includes("quick_quiz")) {
        ContentService.updateContentAttemptAnswer(
          {
            attemptId: attempt?.id,
            questionId: questions[currentQuestion]?.id,
            courseId: LocalStorage.getItem("COURSE_ID"),
          },
          answer,
          () => {
            setHideLastQuestion(questions?.length === currentQuestion + 1);
            setCurrentTextAnswer("");
            setVisitedQuestion(currentQuestion);
            handleNext();
            setOptionChanged(false);
          },
          () => {}
        );
      } else {
        type == "exam"
          ? ExerciseService.updateAnswer(params, answer, () => {
              setHideLastQuestion(questions?.length === currentQuestion + 1);
              setCurrentTextAnswer("");
              setVisitedQuestion(currentQuestion);
              handleNext();
              setOptionChanged(false);
            })
          : ExamService.updateAnswer(params, answer, () => {
              setHideLastQuestion(questions?.length === currentQuestion + 1);
              setCurrentTextAnswer("");
              setVisitedQuestion(currentQuestion);
              handleNext();
              setOptionChanged(false);
            });
      }
    } catch (error) {}
  };

  const handleCompleteQuiz = () => {
    setFinish(true);
  };

  const goBack = () => {
    setCurrentQuestion(0);
    if (location.pathname.includes("paper")) {
      const pathname = generatePath(state?.url, {
        examId: location?.state?.examId,
        ...params,
        ...state,
      });
      history.replace({
        pathname,
        state: location?.state,
      });
    } else if (location.pathname.includes('quick_quiz') || location.pathname.includes('results')) {
      const pathname = generatePath(state?.url, {
        examId: location?.state?.examId,
        ...params,
        ...state,
      });
      history.replace({
        pathname,
        state: location?.state,
      });
    } else {
      const pathname = generatePath(state?.url, {
        subjectId: location?.state?.subjectId,
        ...params,
        ...state,
      });
      history.replace({
        pathname,
        state: location?.state,
      });
    }
  };

  const completeExam = () => {
    if (location.pathname.includes("paper")) {
      PreviousPaperService.completePaperExam(
        {
          attemptId: attempt?.id,
          chapterId: params?.id,
        },
        () => {
          PreviousPaperService.getPaperQuestionsWithAnswers(
            attempt?.id,
            (questions) => {
              setQuestions(questions);
              setCurrentQuestion(0);

              history.replace({
                pathname: generatePath(PAPER_EXAM_RESULTS, {
                  id: params?.id,
                }),
                state: location?.state,
              });
            },
            () => {}
          );

          //goBack();
        },
        () => {}
      );
    } else if (location.pathname.includes("quick_quiz")) {
      ContentService.completeContentExam(
        {
          attemptId: attempt?.id,
          contentLibraryId: state?.contentLibraryId,
          courseId: LocalStorage.getItem("COURSE_ID"),
          chapterId: location?.state?.chapterId,
        },
        () => {
          ContentService.getContentQuestionsWithAnswers(
            attempt?.id,
            (questions) => {
              setQuestions(questions);
              setCurrentQuestion(0);
              history.replace({
                pathname: generatePath(QUICK_QUIZ_RESULTS, {
                  id: params?.id,
                  ...params,
                }),
                state: location?.state,
              });
            },
            () => {}
          );

          //goBack();
        },
        () => {
          goBack();
        }
      );
    } else {
      type == "exam"
        ? ExerciseService.completeExam(
            {
              attemptId: attempt?.id,
              chapterId: params?.id,
            },
            () => {
              //goBack();
              ExerciseService.getExamQuestionsWithAnswers(
                attempt?.id,
                (questions) => {
                  setQuestions(questions);
                  setCurrentQuestion(0);
                  history.replace({
                    pathname: generatePath(EXERCISE_EXAM_RESULTS, {
                      id: params?.id,
                    }),
                    state: location?.state,
                  });
                }
              );
            }
          )
        : ExamService.completeExam(
            {
              attemptId: attempt?.id,
              chaptorId: params?.id,
            },
            () => {
              // goBack();
              ExamService.getExamQuestionsWithAnswers(
                attempt?.id,
                (questions) => {
                  setQuestions(questions);
                  setCurrentQuestion(0);
                  history.replace({
                    pathname: generatePath(EXERCISE_EXAM_RESULTS, {
                      id: params?.id,
                    }),
                    state: location?.state,
                  });
                }
              );
            }
          );
    }
  };

  const handleRetakeTest = () => {
    setInfoPopup(true);
  };
  const generateQuestions = (attemptId: any) => {
    if (location.pathname.includes("paper")) {
      PreviousPaperService.getPaperQuestions(
        attemptId,
        (questions: QuestionModel[]) => {
          setQuestions(questions);
          setInfoPopup(false);
          history.replace({
            pathname: generatePath(PAPER_EXAM, {
              id: params?.id,
            }),
            state: location.state,
          });
        },
        () => {}
      );
    } else if (location.pathname.includes("quick_quiz")) {
      ContentService.getContentQuestions(
        attemptId,
        (questions) => {
          setQuestions(questions);
          setInfoPopup(false);
          history.replace({
            pathname: generatePath(QUICK_QUIZ, {
              id: params?.id,
              ...params,
            }),
            state: location.state,
          });
        },
        () => {}
      );
    } else {
      const getQuestionService =
        type == "exam"
          ? ExerciseService.getExamQuestions
          : ExamService.getExamQuestions;

      getQuestionService(attemptId, (questions: QuestionModel[]) => {
        setQuestions(questions);
        setInfoPopup(false);
        history.replace({
          pathname: generatePath(EXERCISE_EXAM, {
            id: params?.id,
          }),
          state: location.state,
        });
      });
    }
  };
  const quitExam = () => {
    if (location.pathname.includes("paper")) {
      PreviousPaperService.deletePaperExerciseAttempt(
        attempt?.id,

        () => {
          goBack();
        },
        () => {}
      );
    } else if (location.pathname.includes("quick_quiz")) {
      ContentService.deleteContentExerciseAttempt(
        params?.id,
        attempt?.id,
        location?.state?.chapterId,
        () => {
          goBack();
        },
        () => {
          goBack();
        }
      );
    } else {
      type == "exam"
        ? ExerciseService.deleteExamExerciseAttempt(
            params?.id,
            attempt?.id,
            () => {
              goBack();
            },
            () => {}
          )
        : ExamService.deleteExerciseAttempt(
            params?.id,
            attempt?.id,
            () => {
              goBack();
            },
            () => {}
          );
    }
  };

  const handleOk = () => {
    if (location.pathname.includes("paper")) {
      PreviousPaperService.resetPaperExam(
        {
          attemptId: attempt?.id,
        },
        (examAttempt: any) => {
          setCurrentQuestion(0);
          setExamAttempt(examAttempt);
          generateQuestions(examAttempt?.id);
        },
        () => {}
      );
    } else if (location.pathname.includes("quick_quiz")) {
      ContentService.resetContentExam(
        {
          attemptId: attempt?.id,
          courseId: LocalStorage.getItem("COURSE_ID"),
          contentLibraryId: state?.contentLibraryId,
          chapterId: location?.state?.chapterId,
        },
        (examAttempt: any) => {
          setCurrentQuestion(0);
          setExamAttempt(examAttempt);
          generateQuestions(examAttempt?.id);
        },
        () => {}
      );
    } else {
      const resetExamService =
        type == "exam" ? ExerciseService.resetExam : ExamService.resetExam;

      resetExamService(
        {
          chapterId: params?.id,
          attemptId: attempt?.id,
        },
        (examAttempt: any) => {
          setCurrentQuestion(0);
          setExamAttempt(examAttempt);
          generateQuestions(examAttempt?.id);
        }
      );
    }
  };

  const handleAttachment = (file: any) => {
    if (location.pathname.includes("paper")) {
      const jsonData = {
        image: file,
      };
      const formData = convertJSONToFormData(jsonData);
      ExerciseService.createPaperExerciseAttemptQuestionAttachment(
        attempt?.id,
        formData,
        (attachment) => {
          let updatedQuestion = questions[currentQuestion];
          updatedQuestion.excAtmptQuestAttchmts?.push(attachment);
          updateQuestion(updatedQuestion);
        },
        () => {}
      );
    } else if (location.pathname.includes("quick_quiz")) {
      const jsonData = {
        image: file,
      };
      const formData = convertJSONToFormData(jsonData);
      ExerciseService.createContentExerciseAttemptQuestionAttachment(
        attempt?.id,
        formData,
        (attachment) => {
          let updatedQuestion = questions[currentQuestion];
          updatedQuestion.excAtmptQuestAttchmts?.push(attachment);
          updateQuestion(updatedQuestion);
        },
        () => {}
      );
    } else {
      if (type === "exam") {
        const jsonData = {
          image: file,
        };
        const formData = convertJSONToFormData(jsonData);
        ExerciseService.createExamExerciseAttemptQuestionAttachment(
          attempt?.id,
          formData,
          (attachment) => {
            let updatedQuestion = questions[currentQuestion];
            updatedQuestion.excAtmptQuestAttchmts?.push(attachment);
            updateQuestion(updatedQuestion);
          },
          () => {}
        );
      } else {
        const jsonData = {
          image: file,
        };
        const formData = convertJSONToFormData(jsonData);
        ExerciseService.createExerciseAttemptQuestionAttachment(
          attempt?.id,
          formData,
          (attachment) => {
            let updatedQuestion = questions[currentQuestion];
            updatedQuestion.excAtmptQuestAttchmts?.push(attachment);
            updateQuestion(updatedQuestion);
          },
          () => {}
        );
      }
    }
  };

  const handleEndTime = () => {
    if (attempt.timerOptions == "per_question") {
      handleNext();
    } else {
      completeExam();
    }
  };

  const handleQuit = () => {
    if (resultPage) return goBack();
    setQuitQuiz(true);
  };

  return (
    <Fragment>
      <InstructionPopup
        visiblity={infoPopup}
        okHandler={handleOk}
        closeHandler={() => setInfoPopup(false)}
      />
      <PopupModal
        visiblity={finish}
        okHandler={completeExam}
        closeHandler={() => setFinish(false)}
        okText="Finish"
        className="prespecitve-modal"
        withCancel
      >
        <img src={PrespectiveStack} alt="" />
        <h2>Finish the Quiz</h2>
        <p className="remaning">{remaning} Questions</p>
        <p>remains unanswered</p>
      </PopupModal>
      <PopupModal
        visiblity={quitQuiz}
        okHandler={quitExam}
        closeHandler={() => setQuitQuiz(false)}
        okText="Quit"
        closeText="Cancel"
        className="prespecitve-modal"
        withCancel
      >
        <img src={Waring} alt="" />
        <h2>Are you sure to quit the Quiz</h2>
        <p>Answers will not be evaluated until you finish</p>
      </PopupModal>
      <QuestionsDrawer
        handleDrawer={() => setQuestionDrawer((state) => !state)}
        srink={questionsDrawer}
      />
      <div className="exam-pannel__container">
        <div className="close-wrapper" onClick={handleQuit}>
          <img src={CloseIcon} alt="" />
          <span>{resultPage ? "Exit" : "Quit"}</span>
        </div>
        <div className={`exam-card ${questionsDrawer && "exam-card__srink"}`}>
          <Row justify="space-between" align="middle" className="horizontal-padding">
            <Col xs={16} xl={12} xxl={6} className="flex">
              <div className="question-title">
                <p>Question {`${currentQuestion + 1}/${questions?.length}`}</p>
                <Progress
                  showInfo={false}
                  percent={((currentQuestion + 1) / questions?.length) * 100}
                />
              </div>
              {!resultPage && (
                <div
                  className={
                    !questions[currentQuestion]?.marked
                      ? "question-make__review"
                      : "question-marked__review"
                  }
                  onClick={() => setMarkedQuestion(currentQuestion)}
                >
                  <p>
                    <span>
                      {questions[currentQuestion]?.marked ? (
                        <EyeFilled />
                      ) : (
                        <EyeOutlined />
                      )}
                    </span>
                    {questions[currentQuestion]?.marked
                      ? "Marked for review"
                      : "Mark for review"}
                  </p>
                </div>
              )}
            </Col>
            <Col
              xs={8}
              xl={resultPage ? 4 : 6}
              xxl={resultPage ? 2 : 4}
              className={`timer ${
                questions?.length === currentQuestion + 1 &&
                "finish-question-show"
              }`}
            >
              {" "}
              {getTimeType(attempt?.timerOptions, attempt?.time) ? (
                !resultPage ? (
                  <div style={{ width: "100%" }} className="flex align-center">
                    <p>
                      <Timer
                        timerOption={attempt?.timerOptions}
                        seconds={
                          attempt?.timerOptions == "per_question"
                            ? questions[currentQuestion]?.time ?? 0
                            : getTimeType(
                                attempt?.timerOptions,
                                attempt?.time
                              ) ?? 0
                        }
                        secondsHandler={(seconds) => {
                          let question = questions[currentQuestion];
                          question.time = seconds;
                          updateQuestion(question);
                        }}
                        timeEndHandler={handleEndTime}
                      />
                    </p>
                    <div
                      className="finish-question"
                      onClick={handleCompleteQuiz}
                    >
                      Finish
                    </div>
                  </div>
                ) : (
                  <div className={`retake-question`} onClick={handleRetakeTest}>
                    Retake
                  </div>
                )
              ) : !resultPage ? (
                <div className="flex align-center">
                  <div className="finish-question" onClick={handleCompleteQuiz}>
                    Finish
                  </div>
                </div>
              ) : (
                <div className={`retake-question`} onClick={handleRetakeTest}>
                  Retake
                </div>
              )}
            </Col>
          </Row>
          {/* <div className="content__wrapper no-scrollbar"> */}
          <div className='exam__board'>
            <div className="content__wrapper horizontal-padding">
                <Row>
                <Col span={24} className="question">
                <CKEditorComponent
                    value={questions[currentQuestion]?.content ?? ""}
                  />
                  <Row>
                    {questions[currentQuestion]?.questionAttachments?.map(
                      (_, i) => {
                        return (
                          <Col span={7}>
                            <img
                              className="attachment__image"
                              src={_.questionAttachmentUrl}
                              alt=''
                            />
                          </Col>
                        );
                      }
                    )}
                    </Row>
                </Col>
                </Row>

                {/* {!hideLastQuestion && ( */}
                <div
                className={`${
                    questions[currentQuestion]?.onlyFreeText
                    ? ""
                    : "options-wrapper"
                }`}
                >
                {questions[currentQuestion]?.onlyFreeText ? (
                    questions[currentQuestion]?.isCorrect == "pending" ? (
                    <div className="solution-wrapper">
                        <p className="solution-heading">
                        <span>
                            <HourglassOutlined />
                        </span>
                        Evaluating
                        </p>
                        <Row>
                        {questions[currentQuestion]?.excAtmptQuestAttchmts?.map(
                            (_, i) => {
                            return (
                                <Col span={7}>
                                <img
                                    className="attachment__image"
                                    src={_.excAtmptQuestAttchmtUrl}
                                />
                                </Col>
                            );
                            }
                        )}
                        </Row>
                        <p className="solution">
                        {questions[currentQuestion]?.studentAnswer}
                        </p>
                    </div>
                    ) : questions[currentQuestion]?.isCorrect == "yes" ||
                    questions[currentQuestion]?.isCorrect == "no" ? (
                    <div
                        className={
                        questions[currentQuestion]?.isCorrect == "yes"
                            ? "correct-answer-wrapper"
                            : "incorrect-answer-wrapper"
                        }
                    >
                        {questions[currentQuestion]?.isCorrect == "yes" ? (
                        <p className="correct-answer-heading">
                            <span>
                            <CheckCircleOutlined />
                            </span>
                            Correct
                        </p>
                        ) : (
                        <p className="incorrect-answer-heading">
                            <span>
                            <CloseCircleOutlined />
                            </span>
                            Incorrect
                        </p>
                        )}

                        <p className="comment">
                        {questions[currentQuestion]?.studentAnswer}
                        </p>
                    </div>
                    ) : (
                    <>
                        <TextArea
                        className={`quiz-free-text-input
                                            ${questions[currentQuestion]?.isCorrect === "yes" && "option-correct"}
                                            ${questions[currentQuestion]?.isCorrect === "no" && "option-wrong"}`}
                        placeholder={"Enter your answer here"}
                        value={questions[currentQuestion].studentAnswer}
                        disabled={resultPage}
                        onChange={(e) => {
                            setCurrentTextAnswer(e.target.value);
                            let data = questions[currentQuestion];
                            data.studentAnswer = e.target.value;
                            updateQuestion(data);
                        }}
                        />
                        <Attachment
                        enableUpload={!resultPage}
                        data={questions[currentQuestion]?.excAtmptQuestAttchmts}
                        onUpload={(file) => {
                            handleAttachment(file);
                        }}
                        handleDelete={(index) => {
                            let updatedQuestion = questions[currentQuestion];
                            updatedQuestion.excAtmptQuestAttchmts?.splice(index, 1);
                            updateQuestion(updatedQuestion);
                        }}
                        />
                    </>
                    )
                ) : (
                    Object.entries(questions[currentQuestion]?.options ?? {})?.map(
                    ([key, text]) =>
                        text && (
                        <Option
                            text={text}
                            label={key}
                            key={key}
                            selected={
                            key === questions[currentQuestion]?.studentAnswer
                            }
                            onSelectHandler={handleSelect}
                            disabled={
                            resultPage ||
                            (attempt.timerOptions == "per_question" &&
                                questions[currentQuestion]?.time == 0)
                            }
                            correct={
                            key === questions[currentQuestion]?.correctAnswer
                            }
                            wrong={
                            key === questions[currentQuestion]?.studentAnswer &&
                            questions[currentQuestion]?.isCorrect === "no"
                            }
                        />
                        )
                    )
                )}
                </div>
                {/* )} */}

                {resultPage ? (
                !questions[currentQuestion]?.onlyFreeText ||
                questions[currentQuestion]?.isCorrect != "pending" ? (
                    <div>
                    <div className="solution-wrapper">
                        <p className="solution-heading">
                        <span>
                            <SolutionOutlined />
                        </span>
                        Solution
                        </p>
                        <Row>
                        {questions[
                            currentQuestion
                        ]?.questionSolutionAttachments?.map((_, i) => {
                            return (
                            <Col span={7}>
                                <img
                                className="attachment__image"
                                src={_.questionSolutionAttachmentUrl}
                                />
                            </Col>
                            );
                        })}
                        </Row>
                        <CKEditorComponent
                        value={questions[currentQuestion]?.solution ?? ""}
                        />
                        {/* <p className="solution">
                        {questions[currentQuestion]?.solution}
                        </p> */}
                    </div>
                    {questions[currentQuestion]?.comment && (
                        <div className="comment-wrapper">
                        <p className="comment-heading">
                            <span>
                            <CommentOutlined />
                            </span>
                            Tutor Comment
                        </p>
                        <p className="comment">
                            {questions[currentQuestion]?.comment}
                        </p>
                        </div>
                    )}
                    </div>
                ) : (
                    ""
                )
                ) : null}
            </div>
            <ExamPannelController
                resultPage={resultPage}
                freeTextAnswer={currentTextAnswer}
                selected={optionChanged}
                onNextHandler={handleNext}
                onPrevHandler={handlePrev}
                submitHandler={handleSubmit}
                disableSkip={questions[currentQuestion]?.studentAnswer ?? null}
                disableSubmit={
                attempt?.timerOptions == "per_question" &&
                questions[currentQuestion]?.time == 0
                }
                disableNext={currentQuestion === questions?.length - 1}
                disablePrev={currentQuestion === 0}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TitleContainer(AuthContainer(QuestionContainer(ExamPannel)));
